









import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Loading, Divider } from "vant";

@Component({
  components: {
    [Loading.name]: Loading,
    [Divider.name]: Divider,
  },
})
export default class BottomLoadingLine extends Mixins(Mixin) {
  @Prop({ type: Boolean, default: false })
  loading!: boolean;
}
