










































































































































































































































































































































































































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import {
  Col,
  Row,
  Image,
  Search,
  Tabs,
  Tab,
  NoticeBar,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Icon,
  PullRefresh,
  Sticky,
  Popup,
  Overlay,
  Dialog,
  Loading,
} from "vant";
import { Mixin } from "@/core/mixins/mixin";
import Recommend from "@/components/Recommend.vue";
import HotRanksV2 from "@/views/Rank/HotRanksV2.vue";
import TicketGrabs from "./TicketGrabs/TicketGrabs.vue";
import MetalRegion from "@/components/Home/MetalRegion.vue";
import { Route, NavigationGuardNext } from "vue-router";
import { getCityCodeByAdcode } from "@/utils/index";
import Show from "@/views/Show/Show.vue";
import CategorySelector from "@/components/CategorySelector.vue";
import CalendarSelector from "@/components/CalendarSelector.vue";
import { classForm, Recommends } from "@/shims-video";
import { monitorEvent } from "@/utils/youMengMonitor";
import ItemTitle from "./Mall/Payment/ItemTitle.vue";

@Component({
  name: "home",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Image.name]: Image,
    [Search.name]: Search,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    [Sticky.name]: Sticky,
    [Popup.name]: Popup,
    [Overlay.name]: Overlay,
    [Dialog.Component.name]: Dialog.Component,
    [Loading.name]: Loading,
    Recommend,
    HotRanksV2,
    TicketGrabs,
    MetalRegion,
    Show,
    CategorySelector,
    CalendarSelector,
  },
})
export default class Home extends Mixins(Mixin) {
  topOffset = 0.84; // 顶部位移高度
  public aaa = require("@/views/LimitActivities/FlashSale.vue");
  public show = true;
  public notice: Array<cms.TheaterAdvertDto> = [];
  public images: Array<cms.TheaterAdvertDto> = [];
  public searchterm: Array<cms.TheaterAdvertDto> = [];
  public current = "0";
  public secondBannerImage = "";
  // 用于次banner判断
  public ImagesDetails: cms.TheaterAdvertDto = {};
  public refreshing = false;
  public tabBg = "#ffcd0f";
  public bannerBg =
    "url(" +
    require("@/assets/images/Home/Mask备份@2x.png") +
    ")  no-repeat 0px 0px /  100% 80%";
  public storeSearchBg = "#ffcd0f";
  public secondBannerBg = "#fff";
  public showSecondBanner = false;
  public columnBg = "#fff";
  public recommendValue = "recommend";
  public recommendRankList = {};
  public heightRankList = {};
  public selectRankList = {};
  public singleRecommendList = {};
  public choseTypeBtnText = "一月内";
  // 演出页面 默认 时间一个月内
  public searchform: any = {
    discountType: "",
    priceEnum: "",
    showType: "",
    startTimeStamp: new Date().getTime(),
    endTimeStamp: new Date().getTime() + 3600 * 1000 * 24 * 30,
    type: "month",
  };
  searchKeyword = "";
  scrollTop = 0; // 滚轮到顶部的距离

  categoryList: Array<good.ChannelDetailDto> = []; // 频道
  rankList: Array<good.BillboardDto> = []; // 榜单

  categoryTabName = "0";
  advertImagePopupModel = true;
  isBackFrom = "";
  themes: cms.ThemeDto = {};
  iframeHeight = 0;
  loadingFrame = false;
  showDialog = false;
  AntiFraudText = "";
  canAntiFraudDialog = false; //条件是否允许弹窗 提醒防诈骗
  antiFraudDialog = false;
  closetime = 6;
  timer: any = null;
  confirmButtonDisabled = true;
  cityName = "";
  cityCode = "";
  // 多处使用的背景色
  get commonBackgroundColor(): string {
    return this.scrollTop > 0 || this.categoryTabName != "0"
      ? "#fff"
      : this.storeSearchBg;
  }

  // Tab 内容的 padding-top，根据是否开启广告栏而变化
  get tabPaddingTop(): string {
    return this.HomeDataModule.showNotice && this.notice.length > 0
      ? "0.47rem"
      : "0";
  }

  // 搜索框背景色
  get searchbackgroundcolor(): boolean {
    return this.scrollTop > 0 || this.categoryTabName != "0" ? true : false;
  }
  //分类tab背景
  get tabBackgroundColorCopy(): string {
    return this.scrollTop > 0 || this.categoryTabName != "0"
      ? "#fff"
      : "transparent";
  }
  // 分类Tab的背景色
  get tabBackgroundColor(): string {
    return this.scrollTop > 0 || this.categoryTabName != "0"
      ? "#fff"
      : this.tabBg;
  }
  get tabClassfy(): string {
    return this.scrollTop > 0 || this.categoryTabName != "0"
      ? "#fff"
      : "transparent";
  }
  get categoryTabMap(): Record<string, good.ChannelDetailDto> {
    let categoryList = this.categoryList;
    if (categoryList && categoryList.length > 0) {
      let categoryTabMap: Record<string, good.ChannelDetailDto> = {};
      categoryList.forEach((category) => {
        let pindaoId = category.pindaoId;
        if (pindaoId) {
          categoryTabMap[pindaoId] = category;
        }
      });
      return categoryTabMap;
    }
    return {};
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      let home = vm as Home;
      if (home.LocationModule.isChangeCity) {
        home.HomeDataModule.SET_homeCategoryTabName_PERSIST("0");
        home.LocationModule.SET_isChangeCity_PERSIST(false);
      }
      let isBackFrom = home.isBackFrom;
      if (isBackFrom) {
        home.isBackFrom = "";
      }
      home.InterfaceCacheModule.getCacheProductIds(() => {
        // 获取首页需要缓存的接口数据
        home.getHomePageDatas();
        if (!isBackFrom || isBackFrom != from.path) {
          /**
           * isBackFrom 为空说明不是从 Home 出去的或者不需要保持位置的
           * isBackFrom != from.path 表示是从 Home 出去的，但是不是从出去的第一个页面回来的
           */
          home.refreshPage(); // 刷新分页数据
        }
      });
      home.$nextTick(() => {
        home.addRecommendScroll();
      });
    });
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    this.removeRecommendScroll();
    next();
  }

  created(): void {
    /**
     * keep-alive 下的 created 表示首次进入应用时执行
     */
    this.locateAndChangeCity();
    // 是否可以弹出防诈骗提醒
    // 判断缓存是否有提示弹出日期
    let currentData = localStorage.getItem("downloadAppData");
    if (currentData !== this.getData()) {
      this.canAntiFraudDialog = true;
    }
    localStorage.setItem("downloadAppData", this.getData());
  }
  getData(): string {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return year + "-" + month + "-" + day;
  }

  activated(): void {
    let tabHeight = document.getElementsByClassName(
      "van-hairline--top-bottom"
    )[0].scrollHeight; //底部tab的高度
    let oneHeight = document.getElementsByClassName("sticky")[0].scrollHeight; //首页顶部的高度
    let twoHight =
      document.getElementsByClassName("tab-holder")[0].scrollHeight;
    this.iframeHeight =
      document.documentElement.clientHeight - oneHeight - twoHight - tabHeight;
  }
  goToOldVersion(): void {
    this.$api.dictionaryApi.performance.switchEndpoints(({ data }) => {
      if (data.pth5SwitchEndpoint) {
        window.location.href = data.pth5SwitchEndpoint.oldUrl as string;
      }
    });
  }

  /**
   * 获取首页需要缓存的接口数据
   * 有需要缓存的项目 ID 且缓存命中，则从缓存获取首页数据
   * 否则调用接口获取首页数据
   */
  getHomePageDatas(): void {
    this.InterfaceCacheModule.getDatas([
      // 频道
      {
        cacheKey: "channelDetail.searchChannelDetail",
        apiMethod: this.$api.goodApi.channelDetail.searchChannelDetail,
        callback: ({ data }) => {
          this.categoryList = data;
          /**
           * 根据缓存设置分类 Tab 的初始值
           * 更新 van-tab 列表会导致 v-model 被重置为第一个元素
           * 因此需要在更新 van-tab 列表后的 nextTick 中再将缓存设置为 v-model
           */
          this.$nextTick(() => {
            this.categoryTabName = this.HomeDataModule.homeCategoryTabName;
          });
        },
      },
      // 榜单
      {
        cacheKey: "billboard.floorBillboard",
        apiMethod: this.$api.goodApi.billboard.floorBillboard,
        callback: ({ data }) => {
          this.rankList = data.billboardDtos || [];
        },
      },
      // 主题
      {
        cacheKey: "commonInfo.theme",
        apiMethod: this.$api.cmsApi.commonInfo.theme,
        callback: ({ data }) => {
          this.HomeDataModule.SET_theme(data);
          // 设置完主题后，获取次banner&&主题
          this.secondBannerRecieve();
        },
      },
      // 搜索框 广告词条
      {
        cacheKey: "advert.cityAdverts",
        apiMethod: this.$api.cmsApi.advert.cityAdverts,
        params: ["APP_SEARCH"],
        callback: ({ data }) => {
          this.searchterm = data;
        },
      },
      // 公告
      {
        cacheKey: "advert.cityAdverts",
        apiMethod: this.$api.cmsApi.advert.cityAdverts,
        params: ["APP_NOTICE"],
        callback: ({ data }) => {
          this.notice = data;
          // if (this.notice.length > 0) {
          //   this.HomeDataModule.SET_showNotice_PERSIST(true);
          // } else {
          //   this.HomeDataModule.SET_showNotice_PERSIST(false);
          // }
        },
      },
      // 获取 banner
      {
        cacheKey: "advert.cityAdverts",
        apiMethod: this.$api.cmsApi.advert.cityAdverts,
        params: ["APP_BANNER"],
        callback: ({ data }) => {
          this.images = data;
        },
      },
    ]);
  }

  /**
   * 点击【启动广告】
   */
  clickOpenADEvent(item: cms.TheaterAdvertDto): void {
    monitorEvent("OpenAD", "点击启动广告"); // 埋点：首页，点击启动广告
    this.goToJump(item.keyWord, item.linkId, item.linkType, item.linkUrl);
  }

  beforeChange(name: string) {
    if (name !== "0") {
      let item = this.categoryList.find((item) => item.pindaoId === name);
      if (item && item.linkType && /02|14|42/.test(item.linkType)) {
        this.goToJump("", item.linkRelationId, item.linkTypeEnum, item.linkUrl);
        return false;
      }
    }
    return true;
  }
  handleChange(name: string, title: string) {
    if (name == "0") {
      // 点击精彩推荐的 Tab，绑定精彩推荐的分页滚动事件
      this.addRecommendScroll();
    } else {
      // 点击非精彩推荐的 Tab，移除精彩推荐的分页滚动事件
      this.removeRecommendScroll();
    }
    this.HomeDataModule.SET_homeCategoryTabName_PERSIST(this.categoryTabName);
    monitorEvent("HomeType", "点击分类名称", title); // 埋点：首页，点击分类名称
  }

  /**
   * 点击主 Banner
   */
  clickMainBanner(image: cms.TheaterAdvertDto, sort: number): void {
    monitorEvent(
      "Home_ClickBanner",
      "点击一个banner",
      image.advertName || "",
      sort
    ); // 埋点：首页，点击一个banner
    this.goToJump(image.keyWord, image.linkId, image.linkType, image.linkUrl);
  }

  /**
   * 添加精彩推荐的分页滚动事件
   */
  addRecommendScroll(): void {
    let recommendList = this.$refs["recommend-list"];
    if (recommendList) {
      (recommendList as Recommend).addScroll();
    }
  }

  /**
   * 移除精彩推荐的分页滚动事件
   */
  removeRecommendScroll(): void {
    let recommendList = this.$refs["recommend-list"];
    if (recommendList) {
      (recommendList as Recommend).removeScroll();
    }
  }

  /**
   * 定位并检验是否弹出切换城市的提示
   */
  locateAndChangeCity(): void {
    this.amapLocate(
      (newCityCode) => {
        let LocationModule = this.LocationModule;
        let oldCityCode = LocationModule.cityCode;
        if (!oldCityCode) {
          // 因为没有缓存的城市编码，因此直接查询字典，并设置城市
          this.findCity(newCityCode, (cityName, cityCode) => {
            if (cityCode) {
              LocationModule.SET_city_PERSIST({ cityName, cityCode });
            } else {
              // 不在可选城市中，设置为默认城市
              LocationModule.setDefaultCity_PERSIST();
            }
            this.openAntiFraud(); //弹出防诈骗提醒
            // this.recieveAdverts();
          });
        } else if (newCityCode != getCityCodeByAdcode(oldCityCode)) {
          /**
           * 存在缓存的城市编码，并且缓存的城市编码与当前定位的城市编码不同
           * 先查询字典，看看新的城市是不是可选城市，如果不是，则不询问直接设置为默认城市
           * 如果新定位的城市是可选城市，则询问用户是否切换城市，如果用户选择是，则设置新的城市
           */
          this.findCity(newCityCode, (cityName, cityCode) => {
            if (cityCode && cityName) {
              // 新定位城市是可选城市，询问用户是否切换城市
              this.showDialog = true;
              this.cityName = cityName;
              this.cityCode = cityCode;
            }
          });
        } else {
          this.openAntiFraud(); //弹出防诈骗提醒
          // this.recieveAdverts();
        }
      },
      () => {
        this.openAntiFraud(); //弹出防诈骗提醒
        // this.recieveAdverts(); // 弹出广告
      }
    );
  }
  changeCity(): void {
    this.LocationModule.SET_city_PERSIST({
      cityName: this.cityName,
      cityCode: this.cityCode,
    });
    this.refreshPage();
    this.getHomePageDatas();
    this.openAntiFraud(); //弹出防诈骗提醒
    // this.recieveAdverts(); // 弹出广告
  }
  onCloseDialog(): void {
    this.showDialog = false;
    this.openAntiFraud(); //弹出防诈骗提醒
    // this.recieveAdverts(); // 弹出广告
  }
  beforeCloseDialog(action: any, done: any): void {
    if (action == "confirm") {
      return done(false);
    } else {
      return done();
    }
  }
  // 弹出防诈骗提醒
  closeAntiFraud(): boolean {
    if (this.confirmButtonDisabled) {
      return false;
    }
    this.antiFraudDialog = false;
    this.recieveAdverts(); // 弹出广告
    return true;
  }
  openAntiFraud(): void {
    this.$api.cmsApi.commonInfo.policy(
      "FRAUD_PREVENTION_REMINDER" as any,
      ({ data }) => {
        this.AntiFraudText = data;
        if (!data.length) {
          this.canAntiFraudDialog = false;
        }
        if (this.canAntiFraudDialog) {
          this.antiFraudDialog = true;
          // 开始倒计时
          this.countDown();
        } else {
          this.recieveAdverts(); // 弹出广告
        }
      }
    );
  }
  // 验证码发送倒计时
  countDown(): void {
    if (this.closetime === 0) {
      this.confirmButtonDisabled = false;
      clearTimeout(this.timer);
      return;
    }
    this.closetime--;

    this.timer = setTimeout(() => {
      this.countDown();
    }, 1000);
  }
  /**
   * 查询城市字典
   */
  findCity(
    cityCode: string,
    f: (cityName?: string, cityCode?: string) => void
  ): void {
    this.$api.dictionaryApi.placeCity.getPlaceCities(({ data }) => {
      let otherCitys = data.otherCitys;
      if (otherCitys) {
        let cityCodeNameMap: Record<string, string> = {};
        otherCitys.forEach((item) => {
          let indexCitys: Array<dictionary.PlaceCityDto> =
            item.otherCitys || [];
          indexCitys.forEach((item) => {
            let regionCode = item.regionCode;
            if (regionCode != undefined) {
              let fixedCityName = item.cityName || "";
              cityCodeNameMap[regionCode] = fixedCityName;
              if (fixedCityName == this.LocationModule.defaultCityName) {
                this.LocationModule.SET_defaultCityCode_PERSIST(
                  String(regionCode)
                );
              }
            }
          });
        });
        let cityName = cityCodeNameMap[cityCode];
        f(cityName, cityName ? String(cityCode) : undefined);
      }
    });
  }

  /**
   * 刷新分页数据
   */
  refreshPage(): void {
    this.$nextTick(() => {
      let recommendList = this.$refs["recommend-list"] as Recommend;
      if (recommendList) {
        recommendList.refreshData();
      }
    });
  }
  homeRefresh = false;

  // 刷新下拉刷新需要刷新的内容
  refreshForPullRefresh(): void {
    this.homeRefresh = !this.homeRefresh;
    /**
     * 有需要缓存的项目，则从缓存获取首页数据，并为未命中接口设置缓存
     * 否则调用接口获取首页数据
     */
    // 获取首页需要缓存的接口数据
    this.getHomePageDatas();
    this.refreshPage();
    //在监听vuex和使用计算属性无法获取刷新状态时，通过路由拼参数的方式，让底部导航获得更新（此时路由状态发生改变）
    this.$router.push(`/home?refresh=${this.homeRefresh}`);
    setTimeout(() => {
      this.refreshing = false;
    }, 2000);
  }

  dealUrl(item: good.ChannelDetailDto): string {
    let url = "";
    if (item.linkType === "02") {
      if (item.linkUrl && item.linkUrl.includes("externalaccess")) {
        url = item.linkUrl.replace("/externalaccess", "") + "&isTab=1";
      } else {
        url = item.linkUrl + "&isTab=1";
      }
    }
    if (item.linkType === "14") {
      url = `${location.origin}${location.pathname}#/flash-sale?isTab=1`;
    }
    if (item.linkType === "42") {
      url = `${location.origin}${location.pathname}#/special-theme?id=${item.linkRelationId}&isTab=1`;
    }
    return url;
  }

  /**
   * 点击推荐中的项目
   *
   * @param item 详情信息
   */
  goToDetail(item: Recommends): void {
    let path;
    if (item.tour) {
      path = `/tour/${item.tourId}`;
    } else {
      path = `/projectdetail/${item.productId}/${item.tourId}`;
    }
    this.isBackFrom = path;
    monitorEvent(
      "Home_ClickAItem",
      "点击首页演出列表的一个项目",
      item.productNameShort || ""
    ); // 埋点：首页，点击首页演出列表的一个项目
    this.$router.push({ path });
  }

  /**
   * 打开分类页中的右侧弹框
   */
  openCategorySelector(): void {
    let categorySelector = this.$refs["category-selector"] as CategorySelector;
    if (categorySelector) {
      categorySelector.open();
    }
  }

  /**
   * 分类页中的右侧弹框中提交了筛选条件，调用演出列表中的方法根据查询表单查询数据
   */
  submitSelect(val: classForm): void {
    // 演出页面 默认筛选时间 不影响 渠道分销页面时间筛选
    this.searchform.discountType = val.discountType;
    this.searchform.priceEnum = val.priceEnum;
    this.searchform.showType = val.showType;
    let showList = this.$refs[
      "show-list" + this.categoryTabName
    ] as Array<Show>;
    if (showList && showList.length > 0) {
      showList[0].queryData(val);
    }
  }
  // 分类页面中的左侧弹窗中提交了筛选条件
  selectcalendar(form: {
    startTimeStamp: Date;
    endTimeStamp: Date;
    type: string;
  }): void {
    switch (form.type) {
      case "all":
        this.choseTypeBtnText = "全部时间";
        break;
      case "week":
        this.choseTypeBtnText = "一周内";
        break;
      case "month":
        this.choseTypeBtnText = "一月内";
        break;
      case "weekend":
        this.choseTypeBtnText = "本周末";
        break;
      case "appoint":
        // this.choseTypeBtnText = "指定时间";
        this.choseTypeBtnText = this.formDate(
          String(new Date(form.startTimeStamp).getTime())
        );
        break;
    }
    this.searchform.startTimeStamp = form.startTimeStamp;
    this.searchform.endTimeStamp = form.endTimeStamp;
    this.searchform.type = form.type;
    this.submitSelect(this.searchform);
  }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    return `${y}-${m}-${d}`;
  }
  // 打开选择日历
  openCalendarSelector(): void {
    let calendarSelector = this.$refs["calendar-selector"] as CalendarSelector;
    if (calendarSelector) {
      calendarSelector.open();
    }
  }
  // 关闭pop
  closePop(index: number): void {
    let advertImages = this.HomeDataModule.advertImages;
    if (advertImages && advertImages.length > index) {
      advertImages.splice(index, 1);
      this.HomeDataModule.SET_advertImages_PERSIST(advertImages);
    }
  }

  // 公告，点击事件
  noticeClick(item: cms.TheaterAdvertDto): void {
    monitorEvent("Home_ClickAnnouncement", "点击公告栏"); // 埋点：首页，点击公告栏
    this.goToJump(item.keyWord, item.linkId, item.linkType, item.linkUrl);
  }

  // 获取次banner&&主题
  secondBannerRecieve(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "advert.secondaryBanners",
      apiMethod: this.$api.cmsApi.advert.secondaryBanners,
      callback: ({ data }) => {
        // 刷新金刚区
        let metalRegion = this.$refs["metal-region"] as MetalRegion;
        if (metalRegion) {
          metalRegion.refreshData();
        }
        // 刷新 热门抢票
        let hotSale = this.$refs["hot-sale"] as TicketGrabs;
        if (hotSale) {
          hotSale.getTicket();
        }
        this.ImagesDetails = data.secondBannerDetail!;
        if (data.secondBannerDetail) {
          this.secondBannerImage = data.secondBannerDetail.advertUrl1!;
        } else {
          this.secondBannerImage = "";
        }
        if (
          this.HomeDataModule.theme != undefined &&
          this.HomeDataModule.theme.themeDetails
        ) {
          // 如果主题存在
          this.HomeDataModule.theme.themeDetails.forEach((item: any) => {
            let background = "";
            if (item.columnType == 1) {
              background = item.columnTypeValue!.toString();
            } else {
              background = `url(${item.columnTypeValue!.toString()}) no-repeat  center/100% 100%`;
            }
            if (item.themeColumnType == 1) {
              this.storeSearchBg = background;
            } else if (item.themeColumnType == 2) {
              this.tabBg = background;
            } else if (item.themeColumnType == 3) {
              this.bannerBg = background;
            } else if (item.themeColumnType == 4) {
              this.secondBannerBg = background;
              if (item.columnType == 2) {
                this.showSecondBanner = true;
              } else {
                this.showSecondBanner = false;
              }
            } else if (item.themeColumnType == 5) {
              this.columnBg = background;
            }
          });
        }
      },
    });
  }

  // 弹出广告
  recieveAdverts(): void {
    if (
      this.HomeDataModule.advertImages == null ||
      this.HomeDataModule.advertImages.length === 0
    ) {
      this.InterfaceCacheModule.getData({
        cacheKey: "advert.cityAdverts",
        apiMethod: this.$api.cmsApi.advert.cityAdverts,
        params: ["APP_POPUP"],
        callback: ({ data }) => {
          // 倒叙处理,弹框显示顺序不对
          this.HomeDataModule.SET_advertImages_PERSIST(data.reverse());
        },
      });
    }
  }

  // 演出分类开启
  classify(): void {
    monitorEvent("HomeClickAllType", "点击全部分类按钮"); // 埋点：首页，点击全部分类按钮
    sessionStorage.setItem("tabValue", "");
    this.$router.push("/classify");
  }

  gotoSearchPage(item?: any): void {
    monitorEvent("HomeClickSearch", "首页点击搜索按钮"); // 埋点：首页，首页点击搜索按钮
    if (this.searchterm.length) {
      this.$router.push(
        "/search/show?advertName=" +
          item.advertName +
          "&keyword=" +
          item.keyWord +
          "&linkId=" +
          item.linkId +
          "&linkType=" +
          item.linkType +
          "&linkUrl=" +
          encodeURIComponent(item.linkUrl)
      );
    } else {
      this.$router.push("/search/show?keyword=" + item);
    }
  }

  // 关闭通知栏，将信息存到session中
  onCloseNotice(): void {
    // 设为字符，方便存储
    this.HomeDataModule.SET_showNotice_PERSIST(false);
  }

  onChange(index: string): void {
    this.current = index;
  }

  // 粘性布局滑动触发
  scrollHandle(data: { scrollTop: number }): void {
    this.scrollTop = data.scrollTop;
  }

  goNotice(name: string, type: string): void {
    this.$router.push(`/personal-data/notice?name=${name}&type=${type}`);
  }
}
