











































































































































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Button,
  Image,
  Sticky,
  CountDown,
  Swipe,
  SwipeItem,
} from "vant";
import { formate } from "@/utils/index";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
    [CountDown.name]: CountDown,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  filters: {
    filterTimes(item: string | number) {
      return formate(item!);
    },
  },
})
export default class TicketGrabs extends Mixins(Mixin) {
  refreshData() {
    throw new Error("Method not implemented.");
  }
  toursList: any = [];
  time: string | Date = "";
  countdown = 0;
  showTime = true;
  markFirst = true;
  current = 0;
  list = [1, 2];
  activated(): void {
    this.current = 0;
    this.toursList = [];
    this.getTicket();
  }
  mounted(): void {
    this.toursList = [];
    this.getTicket();
  }
  getTicket(): void {
    this.$api.goodApi.product.getHotSaleProduct(({ data }) => {
      // 国外时间需要加1
      if (data) {
        this.toursList = data;
        const now = new Date().getTime();
        this.toursList.forEach((el: any) => {
          this.$set(el, "showTime", el.saleBeginTime - now > 0 ? true : false);
        });
        this.markFirst = false;
      }
    });
  }
  dealTime(num: number): string {
    let time = "";
    if (num < 10) {
      time = "0" + num.toString();
    } else {
      time = num.toString();
    }
    return time;
  }

  onChange(index: number): void {
    this.current = index;
    const now = new Date().getTime();
    this.toursList[index].showTime =
      Number(this.toursList[index].saleBeginTime) - now > 0 ? true : false;
  }
  checkActive(index: string | number) {
    console.log(index);
    return index == this.current;
  }
  timeChange(timeData: Date): void {
    if (!this.markFirst) {
      this.toursList[this.current].showTime = false;
    }
  }

  /**
   * 点击热门抢票
   *
   * @param toursList 演出信息
   */
  clickRobTicket(toursList: good.HotSaleProductDto): void {
    monitorEvent("Home_ClickBuyTicket", "点击立即抢票"); // 埋点：点击立即抢票
    this.goToProjectDetail(toursList.productId, toursList.tourId);
  }
}
